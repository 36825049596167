import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer() {
    return (
        <div className = "Footer container">
            <p>Gemacht mit <span role="img" aria-label="heart"> ❤️ </span>&nbsp; vom THB App Team</p>
        </div>
    )
}

export default Footer
