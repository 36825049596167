import app from '../assets/app.png'
import docs from '../assets/docs.png'
import cms from '../assets/cms.png'
import feedback from '../assets/feedback.png'
import analytics from '../assets/analytics.png'
import github from '../assets/github.png'
import status from '../assets/status.png'

const items = [{
    "title": "App",
    "subtitle": "Hier spielt die Musik",
    "image": app,
    "link": "https://app.thb.torben.wtf"
},
{
    "title": "GitHub",
    "subtitle": "@THB-App | 🏠 der THB App",
    "image": github,
    "link": "https://github.com/THB-App"
},
{
    "title": "Docs",
    "subtitle": "🧾 Dokumentation der THB App",
    "image": docs,
    "link": "https://docs.app.thb.torben.wtf"
},
{
    "title": "CMS",
    "subtitle": "Hier werden die Inhalte verwaltet 🔥",
    "image": cms,
    "link": "https://cms.app.thb.torben.wtf"
},
{
    "title": "Feedback & Roadmap",
    "subtitle": "Du hast Feedback oder möchtest sehen, was noch folgt?",
    "image": feedback,
    "link": "https://docs.app.thb.torben.wtf"
},

{
    "title": "Analytics",
    "subtitle": "💾 Ein bisschen Daten-🐙 muss sein!",
    "image": analytics,
    "link": "https://analytics.app.thb.torben.wtf"
},
{
    "title": "Systemstatus",
    "subtitle": "📈📉📊 Alle Systeme auf einen Blick",
    "image": status,
    "link": "https://status.app.thb.torben.wtf"
}]

export default items
